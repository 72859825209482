<template>
  <v-container fluid fill-height class="d-flex justify-center align-center">
    <div class="d-flex flex-column justify-center align-center w-100 pa-5">
      <v-img src="../assets/svg/404.svg" class="mb-5" />
      <span
        class="primary-color app-bold-font"
        :class="$vuetify.breakpoint.xs ? 'font-16' : 'font-20'"
      >
        The page not found
      </span>
      <span
        class="gray-font-color mt-2 text-center"
        :class="$vuetify.breakpoint.xs ? 'font-12' : 'font-14'"
      >
        The page you requested could not be found. We're working on it.
      </span>
      <div class="mt-5">
        <v-btn color="#B5E539" @click="goHome">
          Go home
        </v-btn>
      </div>
    </div>
  </v-container>
</template>
<script>
export default {
  methods: {
    goHome() {
      this.$router.push({ name: "home" });
    },
  },
};
</script>
<style scoped></style>
